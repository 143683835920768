.p-register > .inner {
  width: 1100px;
  margin: 0 auto;
}
.p-register > .inner > .steps {
  height: 42px;
  text-align: center;
  margin: 20px 0px 100px 0px;
  border-bottom: 2px solid #dddddd;
}
.p-register > .inner > .steps > .step {
  width: 180px;
  padding: 10px;
  font-size: 18px;
  line-height: 20px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  border-bottom: 2px solid transparent;
}
.p-register > .inner > .steps > .step > .num {
  width: 20px;
  height: 20px;
  font-size: 18px;
  color: #ffffff;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  background-color: #c2c2c2;
}
.p-register > .inner > .steps > .step.active {
  border-color: #41ac76;
}
.p-register > .inner > .steps > .step.active > .num {
  background-color: #41ac76;
}
.p-register > .inner > .content {
  margin: 0px 0px 40px 0px;
}
.p-register > .inner > .content > .form {
  width: 400px;
  margin: 0px auto;
}
.p-register > .inner > .content > .form > .item {
  display: flex;
  margin: 15px 0px;
  line-height: 24px;
  align-items: flex-start;
}
.p-register > .inner > .content > .form > .item > label {
  width: 80px;
  margin: 3px 10px;
  text-align: right;
  display: inline-block;
}
.p-register > .inner > .content > .form > .item > .content {
  flex: 1;
  width: 0px;
}
.p-register > .inner > .content > .form > .item > .content > .v-radio {
  line-height: 30px;
}
.p-register > .inner > .content > .form > .item > .content > .v-button {
  width: 264px;
}
.p-register > .inner > .content > .form > .item > .content > .v-input > button {
  width: 97px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #ffffff;
  padding: 0px 15px;
  background-color: #41ac76;
  box-shadow: 0 0 0 1px #41ac76;
}
.p-register > .inner > .content > .form > .item > .content > .v-input > button[disabled] {
  cursor: no-drop;
  background-color: #c4c4c4;
  box-shadow: 0 0 0 1px #c4c4c4;
}
.p-register > .inner > .content > .done {
  margin: 100px auto;
  text-align: center;
}
.p-register > .inner > .content > .done > .tip {
  color: #41ac76;
  line-height: 30px;
  margin-bottom: 15px;
}
.p-register > .inner > .content > .done > .tip > i.icon-success {
  margin: 10px;
  font-size: 36px;
  vertical-align: middle;
}
.p-register > .inner > .content > .done > .tip > span {
  font-size: 18px;
  vertical-align: middle;
}
.p-register > .inner > .content > .done > .countdown {
  color: #999999;
  margin-left: 46px;
}
